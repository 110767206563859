<template>
  <v-row class="mt-3 pb-3">
    <v-col cols="12" lg="6" class="pb-0">
      <analytic-card
        title="Total en ventas"
        :description="salesIndicator"
      ></analytic-card>
    </v-col>
    <v-col cols="12" md="6" lg="3" class="pb-0">
      <analytic-card
        title="Ticket's vendidos"
        :description="ticketsIndicator"
      ></analytic-card>
    </v-col>
    <v-col cols="12" md="6" lg="3" class="pb-0">
      <analytic-card
        title="Transacciones pendientes"
        :description="transactionsIndicator"
      ></analytic-card>
    </v-col>
    <v-col cols="12" class="d-sm-flex justify-end">
      <v-btn
        class="white--text font-weight-bold mr-5 my-2"
        large
        :disabled="
          ((!user.permissions.reportsDownload ||
            !user.permissions.reportsDownload.update) &&
            user.role == 'ORGANIZATION_USER') ||
          eventOrders.length === 0 ||
          downloadButtonLoader
        "
        color="green"
        @click="downloadReport()"
        rounded
        :block="$vuetify.breakpoint.xsOnly"
        :loading="downloadButtonLoader"
      >
        <v-icon>mdi-download</v-icon>
        Descargar registro
      </v-btn>

      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-width="50"
        offset-y
        left
        bottom
        origin="center center"
        transition="scale-transition"
        rounded="xl"
      >
        <template v-slot:activator="{ on: menu, attrs }">
          <v-tooltip top max-width="350">
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                class="font-weight-bold my-2"
                color="secondary"
                rounded
                v-bind="attrs"
                large
                v-on="{ ...tooltip, ...menu }"
                @click="formatMethods"
                :disabled="
                  !user.permissions.Event || !user.permissions.Event.update
                "
                :block="$vuetify.breakpoint.xsOnly"
              >
                <v-icon left> mdi-cash-multiple </v-icon>
                Métodos de pago
              </v-btn>
            </template>
            <span
              >Selecciona los métodos de pago que estarán disponibles en el
              registro de tu evento</span
            >
          </v-tooltip>
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <img
                  src="@/assets/images/weChamber/wechamber-blue-logo-placeholder.png"
                  alt="WeChamber logo"
                />
              </v-list-item-avatar>

              <v-list-item-content>
                <!--<v-list-item-title>Selecciona los métodos de pago disponibles en tu evento</v-list-item-title>-->
                <p class="font-weight-bold subtitle-1 mb-0">
                  Selecciona los métodos de pago disponibles en tu evento
                </p>
                <p class="secondary--text mb-0">Recuerda guardar cambios</p>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list>
            <v-list-item>
              <v-list-item-action>
                <v-switch
                  v-model="paymentsMethodsAvailables.card"
                  color="amber"
                ></v-switch>
              </v-list-item-action>
              <v-list-item-title>Tarjeta crédito/débito</v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-action>
                <v-switch
                  v-model="paymentsMethodsAvailables.cash"
                  color="amber"
                ></v-switch>
              </v-list-item-action>
              <v-list-item-title>Efectivo</v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-action>
                <v-switch
                  :disabled="
                    !alertVisible && user.stripeAccountType != 'EXPRESS'
                  "
                  v-model="paymentsMethodsAvailables.bankTransfer"
                  color="amber"
                ></v-switch>
              </v-list-item-action>
              <v-list-item-title>
                <div
                  v-if="!alertVisible && user.stripeAccountType != 'EXPRESS'"
                >
                  <v-menu
                    open-on-hover
                    activator="#menuActivator"
                    top
                    offset-y
                    class="pa-5"
                    max-width="300"
                  >
                    <v-list>
                      <v-list-item>
                        <span
                          >No se ha activado el método de pago por SPEI, Hacer
                          click
                          <a href="/mi-cuenta" class="spei-hover">aquí</a> para
                          activarlo.</span
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <div id="menuActivator">
                    <v-icon color="red" size="25" class="pointer pr-2"
                      >mdi-alert-circle
                    </v-icon>
                    <span>Transferencia Bancaria SPEI</span>
                  </div>
                </div>
                <span v-else>Transferencia Bancaria SPEI</span>
              </v-list-item-title>
            </v-list-item>
          </v-list>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="font-weight-bold black--text mx-2"
              color="grey lighten-3"
              elevation="0"
              rounded
              @click="menu = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              class="font-weight-bold black--text mx-2"
              color="accent"
              elevation="0"
              rounded
              :disabled="
                !user.permissions.Event || !user.permissions.Event.update
              "
              @click="updatePaymentMethods()"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-col>
    <v-col cols="12" v-if="false">
      <v-row justify="star">
        <v-col cols="12" sm="2" md="4">
          <p
            class="subtitle-1 font-weight-bold mb-0 mt-3"
            :class="$vuetify.breakpoint.mdAndUp ? 'text-end' : ''"
          >
            Periodo de fechas:
          </p>
        </v-col>
      </v-row>
    </v-col>
    <Pagination
      :searchByName="true"
      :pagination="pagination"
      @search="search"
    />
    <v-col cols="12">
      <v-data-table
        :headers="cols"
        :items="eventOrders.orders"
        v-if="eventOrders.orders.length > 0"
        :options.sync="options"
        :server-items-length="eventOrders.totalDocs"
        class="elevation-0"
        :footer-props="{ 'items-per-page-options': [-1, 15, 30, 50, 100] }"
        hide-default-footer
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr
              v-for="item in items"
              :key="item.id"
              class="text-center"
              :class="[
                $vuetify.breakpoint.xsOnly
                  ? 'v-data-table__mobile-table-row'
                  : '',
              ]"
            >
              <template v-for="colData in cols">
                <td
                  v-if="!customCells.includes(colData.value)"
                  :key="colData.value"
                  :class="
                    $vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''
                  "
                >
                  <div
                    class="font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none"
                  >
                    {{ colData.text }}
                  </div>
                  <div
                    :class="
                      $vuetify.breakpoint.xsOnly
                        ? 'v-data-table__mobile-row__cell'
                        : ''
                    "
                  >
                    {{ item[colData.value] }}{{ colData.extraValue }}
                  </div>
                </td>
                <td
                  v-else-if="colData.value === 'status'"
                  :key="colData.value + '_'"
                  :class="
                    $vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''
                  "
                >
                  <div
                    class="font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none"
                  >
                    {{ colData.text }}
                  </div>
                  <div
                    :class="
                      $vuetify.breakpoint.xsOnly
                        ? 'v-data-table__mobile-row__cell'
                        : ''
                    "
                  >
                    <v-chip
                      class="pa-2"
                      :color="paymentStatuses[item[colData.value]].color"
                      text-color="white"
                    >
                      {{ paymentStatuses[item[colData.value]].text }}
                    </v-chip>
                  </div>
                </td>
                <td
                  v-else
                  :key="colData.value + '-'"
                  :class="
                    $vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''
                  "
                >
                  <div
                    class="font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none"
                  >
                    {{ colData.text }}
                  </div>
                  <div
                    class="d-flex"
                    :class="
                      $vuetify.breakpoint.xsOnly
                        ? 'v-data-table__mobile-row__cell'
                        : ''
                    "
                  >
                    <!-- <icon-button
                      color="blue"
                      @click="downloadInvoice(item.invoiceId)"
                      tooltip="Descargar Factura"
                      :disabled="
                        !item.invoiceId ||
                        ((!user.permissions.reportsDownload ||
                          !user.permissions.reportsDownload.update) &&
                          user.role == 'ORGANIZATION_USER')
                      "
                    >
                      mdi-download
                    </icon-button> -->
                    <v-btn
                      fab
                      text
                      small
                      class="pa-0 ma-0"
                      elevation="0"
                      @click="item.dialog = true"
                    >
                      <v-icon :color="color.secondary"> mdi-pencil </v-icon>
                      <personalized-dialog
                        style="display: none"
                        :dialog="item.dialog"
                        @save="
                          updateOrderInfo(item.id, item.email),
                            setTimeout((item.dialog = false), 2000)
                        "
                        @discard="item.dialog = false"
                      >
                        <template v-slot:alertName>
                          Modificar Información
                        </template>
                        <template v-slot:alertContent>
                          <v-text-field
                            label="Correo elecrónico"
                            filled
                            v-model="item.email"
                            rounded
                            dense
                            :rules="emailRules"
                          >
                          </v-text-field>
                        </template>
                      </personalized-dialog>
                    </v-btn>
                    <!-- Inicio dialog -->
                    <DocumentsDialog
                      :invoiceId="item.invoiceId"
                      :id="item.id"
                      :pdf="example"
                      :paymentMethod="item.paymentMethod"
                      :reference="item.reference"
                      @setDocuments="generateDocuments"
                      @getInvoice="downloadInvoice"
                      @setEmail="fowardReferenceEmail"
                    >
                    </DocumentsDialog>
                  </div>
                </td>
              </template>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-col>
    <v-dialog v-model="reportDialog" width="800" persistent>
      <ReportDialog
        :reportProgress="reportProgress"
        :payoutsLength="reportTotalPages"
        @cancel="cancelReport = true"
      />
    </v-dialog>
  </v-row>
</template>
<script>
import qs from "query-string";
import moment from "moment";
import DocumentsDialog from "../../../../../components/shared/DocumentsDialog.vue";
import Pagination from "../../../../../components/dashboard/pagination/Pagination.vue";
import AnalyticCard from "@/components/dashboard/event/analytics/analytic-card";
import ActionButton from "@/components/shared/ActionButton";
import IconButton from "@/components/shared/IconButton";
import {
  formatPrice,
  getDefaultRangePeriod,
  downloadFile,
  downloadLink,
} from "@/utils";
import Endpoints from "@/share/Endpoints";
import { mapState, mapMutations, mapActions } from "vuex";
import ReportDialog from "@/components/shared/Reports/ReportDialog";
import * as XLSX from "xlsx/xlsx.mjs";
import PersonalizedDialog from "../../../../../components/shared/PersonalizedDialog.vue";
import { validations } from "@/share/validations.js";
import { colors } from "@/constants/colors.js";

export default {
  components: {
    AnalyticCard,
    ActionButton,
    DocumentsDialog,
    IconButton,
    Pagination,
    ReportDialog,
    PersonalizedDialog,
  },
  props: ["eventId"],
  watch: {
    options: {
      async handler() {
        await this.searchTransactionsData();
      },
      deep: true,
    },
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("event", ["eventSelected"]),
    ...mapState("alert", ["alertVisible"]),
    /**
     * Función para dar formato de la fecha inicial.
     */
    initialDateFormatted() {
      if (this.searchData.gte) {
        return this.formatDate(this.searchData.gte.substring(0, 10));
      }
    },
    /**
     * Función para dar formate de la fecha final.
     */
    finalDateFormatted() {
      if (this.searchData.lte) {
        return this.formatDate(this.searchData.lte.substring(0, 10));
      }
    },
    salesIndicator() {
      return `${formatPrice(this.indicators.totalSales)}`;
    },
    ticketsIndicator() {
      const { sold, total } = this.indicators.tickets;
      return `${sold} de ${total}`;
    },
    transactionsIndicator() {
      const { pending, total } = this.indicators.transactions;
      return `${pending} de ${total}`;
    },
  },
  data: () => ({
    reportDialog: false,
    reportProgress: 0,
    reportTotalPages: 1,
    cancelReport: false,
    downloadButtonLoader: false,
    fav: true,
    menu: false,
    infoDialog: false,
    example: "",
    emailRules: validations.emailValidation({ required: false }),
    color: colors,
    message: false,
    hints: true,
    paymentsMethodsAvailables: {
      card: false,
      cash: false,
      bankTransfer: false,
    },
    dateFormat: "YYYY-MM-DD HH:mm:ss",
    // Variables que sirven para el páginado
    dateMenu1: false,
    dateMenu2: false,
    options: {},
    pagination: {
      page: 1,
      limit: 10,
      totalPages: 0,
      gte: null,
      lte: null,
      name: "",
    },
    eventOrders: {},
    indicators: {
      totalSales: 0,
      tickets: {
        sold: 0,
        total: 0,
      },
      transactions: {
        pending: 0,
        total: 0,
      },
    },
    tableOptions: {
      page: 1,
      itemsPerPage: 10,
    },
    orders: [],
    cols: [
      {
        text: "Nombre",
        align: "center",
        value: "name",
      },
      {
        text: "Email",
        align: "center",
        value: "email",
      },
      {
        text: "Fecha de Creación",
        align: "center",
        value: "createdAt",
      },
      {
        text: "Fecha de modificación",
        align: "center",
        value: "updatedAt",
      },
      {
        text: "Método de Pago",
        align: "center",
        value: "paymentMethodFormated",
      },
      {
        text: "Estatus",
        align: "center",
        value: "status",
      },
      {
        text: "Total",
        align: "center",
        value: "total",
      },
      {
        text: "Acciones",
        align: "left",
        value: "actions",
      },
    ],
    reportCols: [
      {
        text: "Nombre",
        align: "center",
        value: "name",
      },
      {
        text: "Email",
        align: "center",
        value: "email",
      },
      {
        text: "Fecha de Creación",
        align: "center",
        value: "createdAt",
      },
      {
        text: "Fecha de modificación",
        align: "center",
        value: "updatedAt",
      },
      {
        text: "Método de Pago",
        align: "center",
        value: "paymentMethod",
      },
      {
        text: "Estatus",
        align: "center",
        value: "status",
      },
      {
        text: "Total",
        align: "center",
        value: "total",
      },
      {
        text: "Razón social",
        align: "center",
        value: "actions",
      },
      {
        text: "Folio de factura",
        align: "center",
        value: "actions",
      },
      {
        text: "Acciones",
        align: "center",
        value: "actions",
      },
    ],

    customCells: ["actions", "status"],
    paymentStatuses: {
      COMPLETED: {
        text: "Completado",
        color: "green",
      },
      PAYMENT_PENDING: {
        text: "Pendiente",
        color: "accent",
      },
      IN_PROGRESS: {
        text: "En Progreso",
        color: "blue",
      },
      EXPIRED: {
        text: "Expirado",
        color: "red",
      },
      FREE: {
        text: "Cupón",
        color: "blue",
      },
    },
    paymentMethods: {
      CREDIT_CARD: "Tarjeta de Crédito/Débito",
      DEBIT_CARD: "Tarjeta de Débito",
      BANK_TRANSFER: "Transferencia Bancaria SPEI",
      CASH: "Efectivo (Oxxo)",
      FREE: "Sin costo",
      COUPON: "Cupón",
    },
    coupon: "",
    hasCoupon: false,
  }),
  async created() {
    const range = getDefaultRangePeriod();
    const format = "YYYY-MM-DD";
    (this.pagination.gte = moment(range.startDate).format(format)),
      (this.pagination.lte = moment(range.endDate).format(format)),
      this.getIndicators();
    await this.getEventOrders();
  },
  methods: {
    ...mapActions("event", ["fetchEventById", "updateEvent"]),
    ...mapMutations("notification", ["show"]),
    ...mapMutations("loader", ["loading", "loaded"]),
    search(Pagination) {
      this.pagination = Pagination;
      this.getIndicators();
      this.getEventOrders();
    },
    async searchTransactionsData() {
      await this.getEventOrders();
    },
    async getIndicators() {
      const endpoint = qs.stringifyUrl({
        url: Endpoints.eventsIndicators.replace(/:eventId/, this.eventId),
        query: this.pagination,
      });

      const response = await this.axios.get(endpoint);

      if (response.status === 200) {
        const {
          totalEventSales,
          pendingTransactions,
          totalTransactions,
          ticketsSold,
          ticketsTotal,
        } = response.data.indicators;
        this.indicators = {
          totalSales: totalEventSales,
          tickets: {
            sold: ticketsSold,
            total: ticketsTotal,
          },
          transactions: {
            pending: pendingTransactions,
            total: totalTransactions,
          },
        };
      }
    },
    async getEventOrders() {
      const endpoint = qs.stringifyUrl({
        url: Endpoints.orders.replace(/:eventId/, this.eventId),
        query: this.pagination,
      });

      const response = await this.axios.get(endpoint);

      if (response.status === 200) {
        this.eventOrders = response.data;
        this.eventOrders.originalOrders = this.eventOrders.orders;
        this.pagination.page = response.data.page;
        this.pagination.totalPages = response.data.totalPages;
        this.eventOrders.orders = this.eventOrders.orders.map((order) => ({
          //  paymentMethod: order?.paymentInformation?.paymentMethod,

          hasCoupon: order.coupon !== undefined,

          ...order?.paymentInformation,
          id: order._id,
          dialog: false,
          reference: order.paymentInformation.referenceLink
            ? true
            : order.paymentReference
            ? true
            : false,
          createdAt: moment(order.createdAt).format(this.dateFormat),
          updatedAt: moment(order.updatedAt).format(this.dateFormat),
          invoiceId: order.invoice,
          organizationId: order.organization,
          status: order.status,
          paymentMethod: order?.paymentInformation?.paymentMethod,
          total: formatPrice(order.total),
          paymentMethodFormated:
            this.paymentMethods[
              order.total === 0 && order.coupon !== undefined
                ? "COUPON"
                : order?.paymentInformation?.paymentMethod
            ],
          coupon: order.coupon,
        }));

        console.log(this.eventOrders.orders);
      }
    },

    generateDocuments(id) {
      // subir cambios
    },
    async updateOrderInfo(id, email) {
      let orderIndex = this.eventOrders.originalOrders.findIndex(
        (event) => event._id == id
      );
      let order = this.eventOrders.originalOrders[orderIndex];
      order.paymentInformation.email = email;
      const response = await this.axios({
        method: "PUT",
        url: `/orders/${order._id}`,
        data: {
          paymentInformation: order.paymentInformation,
        },
      });
    },
    async downloadReport() {
      this.reportDialog = true;
      this.reportProgress = 0;
      let reportArray = [];
      let hasMore = true;

      while (hasMore) {
        if (this.cancelReport == true) {
          this.cancelReport = false;
          this.reportDialog = false;
          return;
        }

        const response = await this.axios({
          url: `/orders/${this.eventId}/report?gte=${this.pagination.gte}&lte=${
            this.pagination.lte
          }&page=${this.reportProgress + 1}&limit=10`,
          method: "GET",
        });
        if (response.status == 200) {
          reportArray = reportArray.concat(response.data.report.orders);
          hasMore = response.data.report.hasNextPage;
          this.reportTotalPages = response.data.report.totalPages;
          this.reportProgress++;
        } else {
          hasMore = false;
        }
      }

      if (this.cancelReport == true) {
        this.cancelReport = false;
        this.reportDialog = false;
        return;
      }

      const ws = XLSX.utils.json_to_sheet(reportArray);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Ventas de Evento");
      XLSX.writeFile(wb, "VentasEvento.xlsx");
      this.reportDialog = false;
    },
    async downloadInvoice(id, download = false, type = "") {
      console.log(id, download, type);
      this.example = "";
      console.log(this.eventOrders.originalOrders);
      try {
        if (type == "reference") {
          const index = this.eventOrders.originalOrders.findIndex(
            (order) => order._id === id
          );
          this.example =
            this.orders[index].paymentInformation.referenceLink ||
            this.orders[index].paymentReference;
          if (download) {
            downloadLink(this.example);
          }
        } else {
          const payload = {
            orderId: id,
            isPreview: !download,
          };
          this.loading();
          const response = await this.axios.post(
            Endpoints.invoicesDownload,
            payload,
            { responseType: "arraybuffer" }
          );
          if (response.status === 200) {
            const { data } = response;
            if (download) {
              downloadFile(data, "application/zip", "invoice.zip");
            } else {
              console.log(response);
              let blob = new File([data], "invoice.pdf", {
                type: `application/pdf`,
              });
              const fileUrl = URL.createObjectURL(blob);
              this.example = fileUrl;
              console.log(fileUrl);
            }
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loaded();
      }
    },
    async fowardReferenceEmail(id, type) {
      const response = await this.axios({
        method: "POST",
        url: Endpoints.invoicesSendEmail,
        data: {
          orderId: id,
          type: type,
        },
      });
      console.log(response);
      if (response.status === 200) {
        this.show({
          text: "Correo reenviado exitosamente!",
          color: "success",
          time: 3000,
        });
      } else {
        this.show({
          text: "Ha ocurrido un error al reenviar correo!",
          color: "error",
          time: 3000,
        });
      }
    },
    async updatePaymentMethods() {
      try {
        this.loading();
        // Acomodar los datos del evento poniendole los métodos de pago actualizados
        let newEventData = JSON.parse(JSON.stringify(this.eventSelected));
        newEventData.paymentMethods.forEach((method) => {
          if (method.name == "CREDIT_CARD") {
            method.active = this.paymentsMethodsAvailables.card;
          } else if (method.name == "CASH") {
            method.active = this.paymentsMethodsAvailables.cash;
          } else if (method.name == "BANK_TRANSFER") {
            method.active = this.paymentsMethodsAvailables.bankTransfer;
          }
        });
        let response = await this.updateEvent(newEventData);
        this.show({
          text: response.message,
          color: response.status ? "primary" : "error",
        });
        // Obtener los datos del evento nuevamente;
        await this.fetchEventById(this.eventId);
        this.menu = false;
      } catch (error) {
        console.log(error);
        this.show({
          text: "¡Ha ocurrido un problema al querer actualizar los métodos de pago disponibles!",
          color: "error",
        });
      } finally {
        this.loaded();
      }
    },
    formatMethods() {
      this.eventSelected.paymentMethods.forEach((method) => {
        if (method.name == "CREDIT_CARD") {
          this.paymentsMethodsAvailables.card = method.active;
        } else if (method.name == "CASH") {
          this.paymentsMethodsAvailables.cash = method.active;
        } else if (method.name == "BANK_TRANSFER") {
          this.paymentsMethodsAvailables.bankTransfer = method.active;
        }
      });
    },
    /**
     * Función para dar formato de fecha YYYY-MM-DD.
     * @param {String} date Fecha a la que se le va a aplicar el formato.
     */
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },
};
</script>
<style scoped>
.spei-hover {
  font-weight: 800;
}
</style>
