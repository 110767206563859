<template>
  <v-dialog v-model="dialog" max-width="900" @click:outside="handleCloseDialog()">
    <v-card>
      <v-card-title>
        <span class="wc-subtitle-1">Crear Nuevo Combo</span>
      </v-card-title>
      <v-card-text>
        <span class="wc-caption-2"
          >Ingresa los detalles para crear un nuevo combo.</span
        >
        <v-form ref="form" v-model="validForm">
          <v-row class="mt-4">
            <v-col cols="6">
              <v-text-field
                v-model="combo.name"
                label="Nombre"
                outlined
                required
                :rules="nameRules"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="combo.description"
                label="Descripción"
                outlined
                required
                :rules="descriptionRules"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="combo.requiredQuantity"
                label="Cantidad requerida"
                type="number"
                required
                outlined
                :rules="requiredQuantityRules"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="combo.discountedQuantity"
                label="Cantidad con descuento"
                type="number"
                required
                outlined
                :rules="discountedQuantityRules"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="combo.ticket"
                :items="tickets"
                item-text="name"
                item-value="_id"
                label="Boleto"
                required
                outlined
                :rules="ticketRules"
              ></v-select>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!validForm"
          class="mb-4 white--text"
          color="#1b273e"
          @click="handleSubmit()"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: "ComboDialog",
  props: {
    eventId: {
      type: String,
      required: true,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
    mode: {
      type: String,
      required: false,
      default: "CREATE",
    },
    comboToUpdate: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      combo: {
        name: "",
        description: "",
        requiredQuantity: 0,
        discountedQuantity: 0,
        ticket: "",
      },
      idToUpdate: "",
      tickets: [],
      validForm: true,
      nameRules: [(v) => !!v || "El nombre es requerido"],
      descriptionRules: [(v) => !!v || "La descripción es requerida"],
      requiredQuantityRules: [
        (v) => !!v || "La cantidad requerida es requerida",
        (v) => v > 0 || "La cantidad requerida debe ser mayor a 0",
        (v) => v < this.eventSelected.ticketsPerOrder || "La cantidad requerida debe ser menor a la cantidad de boletos por orden",
      ],
      discountedQuantityRules: [
        (v) => !!v || "La cantidad con descuento es requerida",
        (v) => v > 0 || "La cantidad con descuento debe ser mayor a 0",
        (v) => v < this.eventSelected.ticketsPerOrder || "La cantidad requerida debe ser menor a la cantidad de boletos por orden",
        (v) => v > this.combo.requiredQuantity || "La cantidad con descuento debe ser mayor a la cantidad requerida",
      ],
      ticketRules: [(v) => !!v || "El boleto es requerido"],
    };
  },

  computed: {
    ...mapState('event', ['eventSelected']),
  },

  methods: {
    handleCloseDialog() {
      this.$refs.form.resetValidation();
      this.$emit("update:dialog", false);
    },
    handleSubmit() {
      if (this.mode === "CREATE") {
        this.createCombo();
      } else {
        this.updateCombo();
      }
    },
    async getTickets() {
      try {
        const response = await this.axios({
          url: `/tickets/${this.eventId}`,
          method: "GET",
        });
        this.tickets = response.data.tickets;
      } catch (error) {
        console.log(error);
      }
    },

    async createCombo() {
      try {
        await this.axios({
          url: `/combos`,
          method: "POST",
          data: {
            combo: this.combo,
          },
        });

        this.$emit("update:dialog", false);
        this.$emit("reloadCombos");
      } catch (error) {
        console.log(error);
      }
    },

    async updateCombo() {
      try {
        await this.axios({
          url: `/combos/${this.idToUpdate}`,
          method: "PUT",
          data: {
            combo: this.combo,
          },
        });

        this.$emit("update:dialog", false);
        this.$emit("reloadCombos");
      } catch (error) {
        console.log(error);
      }
    },
  },
  watch: {
    dialog(newValue, oldValue) {
      if (newValue) {
        if (this.mode === "UPDATE") {
          this.combo = {
            name: this.comboToUpdate.name,
            description: this.comboToUpdate.description,
            requiredQuantity: this.comboToUpdate.requiredQuantity,
            discountedQuantity: this.comboToUpdate.discountedQuantity,
            ticket: this.comboToUpdate.ticket,
          };
          this.idToUpdate = this.comboToUpdate._id;
        } else {
          this.combo = {
            name: "",
            description: "",
            requiredQuantity: 0,
            discountedQuantity: 0,
            ticket: "",
          };
        }
      }
    },
  },
  created() {
    this.getTickets();
  },
};
</script>

<style>
</style>