<template>
  <div>
    <v-row class="mt-5">
      <v-col cols="12" class="d-flex justify-end py-5">
        <v-btn color="accent" class="black--text font-weight-bold rounded-xl" @click="handleCreate()">
          <span>
            <v-icon> mdi-plus </v-icon>
            Nuevo combo
          </span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-4">
      <v-col cols="12">
        <v-data-table
          class="elevation-1"
          item-class="text-center"
          no-data-text="No hay combos registrados"
          :headers="headers"
          :items="combos"
          :items-per-page="-1"
          hide-default-footer
          :loading="loading"
        >
          <template v-slot:[`item.ticket`]="{ item }">
            {{ formatTicket(item.ticket) }}
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon color="accent" @click="handleUpdate(item)">
              <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>
            <v-btn icon color="red" @click="deleteCombo(item._id)">
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <ComboDialog
        :eventId="eventId"
        :dialog.sync="dialog"
        :mode="dialogMode"
        :comboToUpdate="comboToUpdate"
        @reloadCombos="getCombos()"
      />
    </v-row>
  </div>
</template>

<script>
import ComboDialog from "@/components/dashboard/event/combos/ComboDialog.vue";
export default {
  name: "Combos",
  components: {
    ComboDialog,
  },
  props: {
    eventId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        { text: "Nombre", value: "name" },
        { text: "Descripción", value: "description" },
        { text: "Cantidad requerida", value: "requiredQuantity" },
        { text: "Cantidad con descuento", value: "discountedQuantity" },
        { text: "Boleto", value: "ticket" },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      combos: [],
      dialog: false,
      dialogMode: "CREATE",
      comboToUpdate: null,
      tickets: [],
      loading: false,
    };
  },
  methods: {
    handleCreate() {
      this.dialog = true;
      this.dialogMode = "CREATE";
    },
    handleUpdate(combo) {
      this.dialog = true;
      this.dialogMode = "UPDATE";
      this.comboToUpdate = combo;
    },
    formatTicket(ticket) {
      return this.tickets.find((t) => t._id === ticket).name;
    },
    async getTickets() {
      try {
        const response = await this.axios({
          url: `/tickets/${this.eventId}`,
          method: "GET",
        });
        this.tickets = response.data.tickets;
      } catch (error) {
        console.log(error);
      }
    },
    async getCombos() {
      this.loading = true;
      try {
        const response = await this.axios({
          url: `/combos/${this.eventId}`,
          method: "GET",
        });

        this.combos = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async deleteCombo(id) {
      try {
        await this.axios({
          url: `/combos/${id}`,
          method: "DELETE",
        });

        this.getCombos();
      } catch (error) {
        console.log(error);
      }
    }, 
  },
  created() {
    this.getCombos();
    this.getTickets();
  },
};
</script>

<style>
</style> 